<template>
  <swiper
    class="swiper-progress"
    :options="swiperOptions"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
  >
    <swiper-slide v-for="(data, index) in swiperData" :key="index">
      <b-img :src="data.img" fluid class="content-img" />
    </swiper-slide>

    <!-- Add Arrows -->
    <div slot="button-next" class="swiper-button-next" />
    <div slot="button-prev" class="swiper-button-prev" />
    <div slot="pagination" class="swiper-pagination" />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  data() {
    return {
      /* eslint-disable global-require */
      swiperData: [
        {
          img: require("@/assets/images/pages/welcome/brxmind_dashboard 1.svg"),
        },
        { img: require("@/assets/images/pages/welcome/brxmind_kanban 1.svg") },
        {
          img: require("@/assets/images/pages/welcome/brxmind_graficos 1.svg"),
        },
        {
          img: require("@/assets/images/pages/welcome/brxmind_calendario 1.svg"),
        },
        { img: require("@/assets/images/pages/welcome/brxmind_cards 1.svg") },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
    };
  },
};
</script>

<style>
.swiper-button-next,
.swiper-button-prev {
  color: #535896;
}

.swiper-pagination-progressbar-fill {
  border: 2px solid #686096 !important;
  border-radius: 5px;
}
</style>
