<template>
  <div>
    <header>
      <div class="nav-menu">
        <nav class="d-flex justify-content-between nav-bar">
          <div class="logo">
            <div class="d-flex nav-logo">
              <vuexy-logo />
              <div>
                <h2 class="brand-text text-primary r-text">BRXMIND</h2>
                <h2 class="brand-text-pp">Braxxy Software e Projetos</h2>
              </div>
            </div>
          </div>

          <div class="nav-bar-items" id="nav">
            <div id="nav-mobile">
              <ul>
                <li>
                  <b-nav-item-dropdown
                    id="dropdown-grouped"
                    variant="link"
                    class="dropdown-language"
                    right
                  >
                    <template #button-content>
                      <b-img
                        :src="currentLocale.img"
                        height="14px"
                        width="22px"
                        :alt="currentLocale.locale"
                      />
                      <span class="ml-50 text-body">{{
                        currentLocale.name
                      }}</span>
                    </template>
                    <b-dropdown-item
                      v-for="localeObj in locales"
                      :key="localeObj.locale"
                      @click="$i18n.locale = localeObj.locale"
                    >
                      <b-img
                        :src="localeObj.img"
                        height="14px"
                        width="22px"
                        :alt="localeObj.locale"
                      />
                      <span class="ml-50">{{ localeObj.name }}</span>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </li>
                <li>
                  <b-link
                    @click="goToLogin()"
                    class="nav-menu_text nav-menu_login"
                  >
                    Login
                  </b-link>
                </li>

                <li>
                  <b-link class="nav-menu_text" @click="scrollMeTo('contact')">
                    {{ $t("message.welcome.contact") }}
                  </b-link>
                </li>
                <li>
                  <b-link class="nav-menu_text" @click="scrollMeTo('benefics')">
                    {{ $t("message.welcome.beneficNav") }}
                  </b-link>
                </li>
              </ul>
            </div>
            <button @click="showMenu()" id="btn-mobile"></button>
          </div>
        </nav>
      </div>
    </header>

    <div
      class="content mt-5 text-center"
      style="align-items: center; position: relative; margin-left: 0 !important"
      ref="home"
    >
      <h1>
        {{ $t("message.welcome.productivityWith") }}
        <strong class="brand-text text-primary"> BRXMIND </strong>
      </h1>
      <div>
        <p class="content-text mt-1">
          {{ $t("message.welcome.firstLine") }}
        </p>
        <div class="d-flex justify-content-center content-slider mb-3 mt-3">
          <Carroussel-slide />
        </div>
      </div>
    </div>

    <div class="mt-2 content-feature" ref="feature">
      <h2 class="d-flex justify-content-center">
        {{ $t("message.welcome.about") }}
      </h2>

      <b-row class="d-flex" style="justify-content: space-evenly">
        <b-col md="8">
          <p class="content-about" style="text-align: center">
            {{ $t("message.about.text") }}
          </p>
        </b-col>
      </b-row>
    </div>

    <b-row class="feature-row">
      <b-col class="feature-col" md="5">
        <h2 class="title-strategy">
          {{ $t("message.welcome.strategicAnalysis") }}
        </h2>
        <p class="feature_text text-align-left">
          {{ $t("message.aianalysis.text") }}
        </p>
        <div class="content-feature_mobileBox">
          <div class="mt-1 d-flex feature-icon-graphics">
            <b-img src="@/assets/images/pages/welcome/icon-produtivity.svg" />
            <!-- <h3>{{ $t("message.welcome.Graphics") }}</h3>  -->
            <p class="ml-2 mt-1 content-feature_topics">
              {{ $t("message.aianalysis.topic1") }}
            </p>
          </div>

          <div class="mt-1 d-flex feature-icon-produtivity">
            <b-img src="@/assets/images/pages/welcome/icon-time.svg" />
            <!-- <h3>{{ $t("message.welcome.productivity") }}</h3>   -->
            <p class="ml-2 mt-1 content-feature_topics">
              {{ $t("message.aianalysis.topic2") }}
            </p>
          </div>

          <div class="mt-1 d-flex feature-icon-time">
            <b-img src="@/assets/images/pages/welcome/icon-graphics.svg" />
            <!-- <h3>{{ $t("message.welcome.agileMethodology") }}</h3> -->
            <p class="ml-2 mt-1 content-feature_topics">
              {{ $t("message.aianalysis.topic3") }}
            </p>
          </div>
        </div>
      </b-col>

      <b-col md="5">
        <div>
          <b-img
            fluid
            src="@/assets/images/pages/welcome/brxmind_dashboard_dark 1.svg"
            alt="analitycs BRXMIND"
            class="feature_img img-responsive mt-2"
          />
        </div>
      </b-col>
    </b-row>

    <div class="benefics-area">
      <h2 class="title-benefics">
        {{ $t("message.welcome.benefics") }}
      </h2>

      <div ref="benefics" class="benefics d-flex">
        <div class="card-benefics card-benefics-active">
          <h5>{{ $t("message.benefits.card1.title") }}</h5>
          <p>
            {{ $t("message.benefits.card1.message") }}
          </p>
        </div>

        <div class="card-benefics">
          <h5>{{ $t("message.benefits.card2.title") }}</h5>
          <p>
            {{ $t("message.benefits.card2.message") }}
          </p>
        </div>

        <div class="card-benefics">
          <h5>{{ $t("message.benefits.card3.title") }}</h5>
          <p>
            {{ $t("message.benefits.card3.message") }}
          </p>
        </div>

        <div class="card-benefics">
          <h5>{{ $t("message.benefits.card4.title") }}</h5>
          <p>
            {{ $t("message.benefits.card4.message") }}
          </p>
        </div>
      </div>
    </div>
       <div v-if="!cookieAccepted" class="cookie-accept-popup">
          <div class="popup-content">
            <p style="font-size: 0.75rem">
              Utilizamos cookies para melhorar a sua experiência. Ao continuar, você aceita
              nossa
              <b-link 
                to="/PrivacyPolicy"
                style="color: #007BFF"
              >
                Política de Segurança
                <feather-icon icon="ExternalLinkIcon" size="12"/>
              </b-link>
            </p>
            <b-button @click="acceptCookies" style="font-size: 0.75rem">Aceitar</b-button>
          </div>
        </div>
    <div ref="contact">
      <WelcomeFooter />
    </div>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { BLink, BImg, BRow, BTable, BCol } from "bootstrap-vue";
import CarrousselSlide from "./CarrousselSlide.vue";
import CarrouselDevice from "./CarrouselDevice.vue";
import WelcomeFooter from "./WelcomeFooter.vue";
import VueCookies from 'vue-cookies'
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BNavItemDropdown,
  BSidebar,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    VuexyLogo,
    BLink,
    BRow,
    BImg,
    BTable,
    CarrousselSlide,
    CarrouselDevice,
    WelcomeFooter,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BSidebar,
    BCol,
    BButton,
  },
  data() {
    return {
      cookieAccepted: VueCookies.get('cookieAccepted') === 'true',
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "Ingles",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Espanhol",
      },
      {
        locale: "pt",
        img: require("@/assets/images/flags/pt.png"),
        name: "Português",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },

  methods: {
    acceptCookies() {
      VueCookies.set('cookieAccepted', 'true', '6m')  // preferência por 259,200 minutos / 6m
      this.cookieAccepted = true
    },
    showMenu() {
      const btn = document.querySelector("#btn-mobile");
      const nav = document.querySelector("#nav");
      nav.classList.toggle("nav-bar-items");
    },

    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    goToLogin() {
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>

<style scoped>
.cookie-accept-popup {
  position: fixed;
  bottom: 0.5rem;
  left: 25%;
  right: 25%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 99;
}

.dark-layout .cookie-accept-popup {
  background-color: #202125;
  border-color: #1a1d21 !important;
}

.cookie-accept-popup p {
  margin-bottom: 0px;
  margin-right: 10px;
}

.popup-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-bar {
  height: 100%;
  box-shadow: 0px 4px 4px rgb(152 178 255 / 25%);
}

.hr {
  width: 50px;
  height: 0px;
  text-align: center;
  border: 2px solid #686096;
}
.feature_img {
  width: 92%;
  -webkit-box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
  box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
}

#btn-mobile {
  display: none;
  background: none;
  border-width: 0px;
  border-top: 2px solid #000;
  width: 20px;
  padding-left: 0px;
  margin-left: 16%;
}

#btn-mobile::after,
#btn-mobile::before {
  content: "";
  display: block;
  width: 20px;
  height: 2px;
  background: #7367f0;
  margin-top: 5px;
  padding-left: 16%;
}

#btn-mobile {
  display: none;
  background: none;
  border-width: 0px;
  border-top: 2px solid #7367f0;
  width: 20px;
  padding-left: 0px;
  position: absolute;
  right: 40px;
  top: 15px;
}

@media (max-width: 650px) {
  #check:checked ~ ul {
    left: 0;
  }
  #featherIcon {
    display: block;
    margin-left: 18%;
  }
  #btn-mobile {
    display: block;
  }
  #dropdown-grouped {
    padding-right: 25%;
  }
  .nav-menu ul li {
    padding: 10px;
  }

  .nav-logo {
    display: inline-flex;
    justify-content: center;
  }
  .nav-menu ul {
    display: none;
  }

  .nav-bar-items {
    position: fixed;
    z-index: 100;
    color: #fff;
    top: 0;
    width: 50%;
    height: 100%;
    transition: 0.4s;
    box-shadow: 0px 4px 4px rgba(22, 29, 49, 0.25);
    background: #161d31;
    right: 0;

    transition: 0.4s;

    padding-top: 10%;
  }

  .nav-bar-items ul {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    padding-top: 2.5rem;
  }

  .nav-bar-items ul li a {
    padding: 0px;
  }

  #featherIcon {
    position: absolute;
    top: 0.75rem;
    left: -3rem;
    right: 1rem;
    cursor: pointer;
  }
  .card-benefics {
    margin: 2px;
  }

  .feature-col {
    margin: none;
    text-align: center;
  }
  .feature-row {
    justify-content: none;
  }

  .feature-icon-graphics img,
  .feature-icon-produtivity img,
  .feature-icon-time img {
    display: none;
  }
}
.feature-col {
  margin-right: 88px;
}

.feature-row {
  display: flex;
  justify-content: center;
}

.feature {
  padding-top: 10%;
}

.header-logo {
  margin-right: 90rem;
}

.text-primary {
  font-weight: bold;
}
nav {
  padding-top: 10px;
}

.logo {
  padding-left: 1rem;
}

.nav-menu ul {
  list-style: none;
}

.nav-menu ul li {
  display: inline-block;
  padding-right: 1rem;
}

.nav-menu_text {
  font-size: 16px;
  color: #7367f0;
}

.nav-menu_login {
  /* background-image: linear-gradient(
    160deg,
    #ffd9ff 0,
    #efbdff 25%,
    #b99df2 50%,
    #897dc0 75%,
    #626192 100%
  ); */

  border-radius: 5rem 3rem 1rem 10rem;
  color: #7367f0;
  font-weight: 600;
}

.content-slider {
  width: 50%;
  margin: 0 auto;
  border-radius: 0rem 0rem 0rem 0rem;
  -webkit-box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
  box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
}

.dropdown-language {
  right: 0px;
  padding-right: 0px;
}

.content-img {
  width: auto;
}

.content-feature h2,
.title-benefics,
.title-strategy {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #686096;
  padding-bottom: 20px;
}

.feature-row {
  padding-top: 5%;
}
.feature_text {
  font-style: normal;
  font-size: 15px;
}

.content-feature_text {
  margin: 2rem 20rem 5rem 20rem;
}
/* .content-feature_analytics {
  display: inline-block;
} */

.content-feature_img {
  width: 40vw;
  -webkit-box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);
  box-shadow: 0 10px 40px -5px rgba(130, 34, 210, 0.75);

  float: right;
  margin: 0 20rem 5rem 0;
}

.content-feature_icons {
  color: #f8efed;
  background-color: #686096;
  border-radius: 3rem 12rem 12rem 5rem;
  width: 2.5rem;
  height: 2.3rem;
  margin: -0.5rem 1rem 0 0;
}

.content-feature_device {
  width: 50%;
  margin-top: 5rem;
}
.content-feature_deviceBox {
  width: 50%;
  float: right;
  margin-top: -25rem;
}
.content-feature_deviceBox h2 {
  text-align: center;
}
.content-feature_mobile {
  float: right;
  margin: -5rem 18rem 0 0;
}
.content-feature_desktop {
  float: right;
  margin-right: 18rem;
}

@media screen and (min-width: 250px) and (max-width: 812px) {
  .header {
    display: list-item;
  }

  .header-logo {
    display: flex;
    margin-right: auto;
  }

  .header-logo_mobile {
    margin: 2rem 0 2rem 0;
    text-align: center;
    display: block ruby;
  }

  .content-text {
    margin-left: 2rem;
  }

  /* 
  .content-slider {
    width: 100vw;
    height: 40vh;
    margin: 3vw !important;
} */
  .swiper-slide .img-fluid {
    width: 150vw;
  }

  .nav-menu {
    justify-content: center;
  }

  .content {
    margin: 0 2rem -10rem 2rem;
  }

  .content-feature {
    margin-top: 10rem !important;
  }

  .content-feature_text {
    text-align: justify;
    line-height: 1.8rem;
    margin: 0 0 2rem 0;
  }

  .content-feature_img {
    float: initial;
    margin: 0 !important ;
  }

  .content-feature_topics {
    width: 90vw !important;
    margin-top: 2rem;
  }
  .feature-responsive {
    margin: 2rem 0 2rem 2rem;
  }

  .feature-responsive h3 {
    margin-left: -3rem;
  }

  .content-feature_analytics p {
    margin-top: 2rem;
  }

  .content-feature_deviceBox {
    width: auto;
    float: none;
    margin: 2rem 0 2rem 0;
  }
  .content-feature_carrousel {
    display: inline;
    vertical-align: middle;
    margin-left: 20rem;
  }

  .content-feature_mobile,
  .content-feature_desktop {
    float: none;
    margin-left: 2rem;
  }
  .content-feature_mobileBox {
    width: 100vw;
  }

  .content-contact_address {
    margin-left: 0 auto;
    text-align: left;
  }

  .content-google_maps {
    width: 50% !important;
    height: 250px !important;
    margin-left: 2rem !important;
  }
  .content-contact {
    height: auto;
    width: auto;
    display: block;
  }
}

.hr-benefics {
  width: 50px;
  height: 0px;
  text-align: center;
  border: 2px solid #686096;
}

.benefics-area {
  padding-top: 8%;
}

.benefics-area h2 {
  text-align: start;
  margin-left: 10%;
}

.benefics {
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2%;
}
.card-benefics {
  margin-right: 2%;
  width: 337px;
  height: 170px;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}

.card-benefics-active {
  margin-right: 2%;
  width: 337px;
  height: 170px;
  background: #686096;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}

.card-benefics p {
  text-align: center;
  color: #444343;
  font-weight: 100;
  padding-right: 25px;
  padding-left: 25px;
}
.card-benefics-active p {
  text-align: center;
  color: #fff;
  font-weight: 100;
  padding-right: 25px;
  padding-left: 25px;
}

.brand-text-pp {
  /* margin-bottom: -4rem; */
  margin-left: 4px;
  margin-top: -11px;
  font-size: 10px;
  color: #d5d5d5;
}

.r-text {
  margin-left: 3px;
  margin-top: -1px;
  font-size: 20px;
}

.card-benefics h5 {
  padding-top: 13px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  color: #353535;
}

.card-benefics-active h5 {
  padding-top: 13px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  color: #fff;
}

.section-pages {
  padding-top: 8%;
  padding-bottom: 5%;
}

.section-pages .row {
  padding-top: 50px;
}

.pages-context h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 20px;
  color: #fff;
}

.pages-context {
  width: 44rem;
  text-align: start;
  /* display: flex; */
  margin: 0 auto;
}

.section-grapics h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  padding-bottom: 20px;
}

.section-grapics p {
  width: 1211px;
  font-style: normal;
  font-weight: 100;
  line-height: 24px;
  margin: 0 auto;
}
</style>
